import React from 'react';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import GoogleIcon from '@material-ui/icons/Mail';
import { auth } from './config';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { STORAGE_ITEM_TOKEN } from '@jumbo/constants/LocalStorage';

const useStyles = makeStyles(theme => ({
  iconBtn: {
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 6,
    },
  },
}));

const SocialMediaIcons = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const signInUserWithGoogle = () => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithOAuth({ provider: 'google', options: { redirectTo: process.env.REACT_APP_URL } })
        .then(data => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(data.user));
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton className={classes.iconBtn} onClick={signInUserWithGoogle}>
        <GoogleIcon />
      </IconButton>
    </Box>
  );
};

const Supabase = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth.signUp({ email: email, password: password, options: { data: { name: name }, redirectTo: process.env.REACT_APP_URL } })
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogin: ({ email, password }) => {
    console.log('onLogin', email, password);
    return dispatch => {
      try {
        dispatch(fetchStart());
        auth.signInWithPassword({ email: email, password: password, options: { redirectTo: process.env.REACT_APP_URL } })
          .then(({ data, error }) => {
            dispatch(fetchSuccess());
            if (data.session && data.session.user) {
              const authUser = data.session.user;
              const token = data.session.access_token;
              localStorage.setItem(STORAGE_ITEM_TOKEN, token);
              dispatch(
                setAuthUser({
                  uid: authUser.id,
                  displayName: authUser?.user_metadata?.full_name,
                  email: authUser.email,
                  photoURL: authUser?.user_metadata?.avatar_url,
                  token: token,
                }),
              );
            } else {
              dispatch(fetchSuccess());
              dispatch(updateLoadUser(true));
              localStorage.removeItem(STORAGE_ITEM_TOKEN);
            }
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .signOut()
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
            localStorage.removeItem(STORAGE_ITEM_TOKEN);
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: () => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(false));
      try {
        auth.getSession().then(({ data, error }) => {
          if (data.session && data.session.user) {
            const authUser = data.session.user;
            const token = data.session.access_token;
            localStorage.setItem(STORAGE_ITEM_TOKEN, token);
            dispatch(
              setAuthUser({
                uid: authUser.id,
                displayName: authUser?.user_metadata?.full_name,
                email: authUser.email,
                photoURL: authUser?.user_metadata?.avatar_url,
                token: token,
              }),
            );
          } else {
            dispatch(fetchSuccess());
            dispatch(updateLoadUser(true));
            localStorage.removeItem(STORAGE_ITEM_TOKEN);
          }
        }).catch(error => {
          console.log('error', error);
          dispatch(updateLoadUser(true));
          dispatch(fetchError(error.message));
        });
      } catch (error) {
        dispatch(updateLoadUser(true));
        dispatch(fetchError(error.message));
      }
    };
  },

  onForgotPassword: ({ email }) => {
    console.log('email', email);
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .resetPasswordForEmail(email)
          .then(data => {
            dispatch(setForgetPassMailSent(true));
            dispatch(fetchSuccess());
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getSocialMediaIcons: () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box component="p" fontSize={{ xs: 13, sm: 16 }}>
          Or Login with
        </Box>
        <SocialMediaIcons />
      </Box>
    );
  },
};

export default Supabase;
