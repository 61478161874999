//For expanding sidebar
import {
  FETCH_ERROR,
  CREATE_POSITIONS,
  GET_POSITIONS,
  UPDATE_POSITION,
  DELETE_POSITION,
} from '../../@jumbo/constants/ActionTypes';
import { hideMessage, fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import axiosClient from 'services/auth/jwt/config';

//for adding a position
export const createPositionsBatch = positions => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .post('/positions/batch', positions)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_POSITIONS, payload: data.data });
          dispatch(showMessage('Positions created successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const createPosition = position => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .post('/positions', position)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_POSITIONS, payload: [data.data] });
          dispatch(showMessage('Position created successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const getPositions = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.get(`/positions?page=${page}&size=${size}&sort=${sort}&filter=${filter}`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_POSITIONS,
          payload: { data: res.data, totalCount: res.data.length },
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch(hideMessage());
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError('Error fetching data: ' + error));
      dispatch(hideMessage());
    }
  };
};

export const updatePositionsQuotes = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.post(`/positions/update-quotes?page=${page}&size=${size}&sort=${sort}&filter=${filter}`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_POSITIONS,
          payload: { data: res.data, totalCount: res.data.length },
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch(hideMessage());
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError('Error fetching data: ' + error));
      dispatch(hideMessage());
    }
  };
};

export const deletePosition = positionId => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .delete(`/positions/${positionId}`)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_POSITION, payload: positionId });
          dispatch(showMessage('Position deleted successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const updatePosition = position => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .patch(`/positions/${position.id}`, position)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_POSITION, payload: data.data });
          dispatch(showMessage('Position updated successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};
