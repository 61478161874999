//For expanding sidebar
import { GET_BENCHMARK_COMPARISON } from '../../@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axiosClient from 'services/auth/jwt/config';


// get portfolio positions
export const getBenchmarkComparisonData = (comparisonId) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.get(`/benchmark-comparisons/${comparisonId}`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_BENCHMARK_COMPARISON,
          payload: { comparisonId, data: res.data },
        });
      } else {
        dispatch(fetchError('Error while fetching benchmark comparison data'));
      }
    } catch (error) {
      dispatch(fetchError('Error while fetching benchmark comparison data'));
    }
  };
};
