//For expanding sidebar
import {
  FETCH_ERROR,
  CREATE_PORTFOLIO,
  GET_PORTFOLIOS,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
} from '../../@jumbo/constants/ActionTypes';
import { hideMessage, fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import axiosClient from 'services/auth/jwt/config';

//for adding a portfolio
export const createPortfolio = portfolio => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .post('/portfolios', portfolio)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_PORTFOLIO, payload: [data.data] });
          dispatch(showMessage('Portfolio created successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const deletePortfolio = portfolioId => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .delete(`/portfolios/${portfolioId}`)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_PORTFOLIO, payload: portfolioId });
          dispatch(showMessage('Portfolio deleted successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const updatePortfolio = portfolio => {
  return dispatch => {
    dispatch(fetchStart());
    axiosClient
      .patch(`/portfolios/${portfolio.id}`, portfolio)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_PORTFOLIO, payload: data.data });
          dispatch(showMessage('Portfolio updated successfully!'));
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const getPortfolios = (page, size, sort, filter) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.get(`/portfolios?page=${page}&size=${size}&sort=${sort}&filter=${filter}`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_PORTFOLIOS,
          payload: { data: res.data, totalCount: res.data.length },
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch(hideMessage());
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError('Error fetching data: ' + error));
      dispatch(hideMessage());
    }
  };
};

export const recalculatePortfolio = () => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.post(`/portfolios/recalculate?updateQuotes=true`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_PORTFOLIOS,
          payload: { data: res.data, totalCount: res.data.length },
        });
      } else {
        console.error('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data });
        dispatch(hideMessage());
      }
    } catch (error) {
      console.error('Error****:', error);
      dispatch(fetchError('Error fetching data: ' + error));
      dispatch(hideMessage());
    }
  };
};
