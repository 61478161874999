import { CLEAR_STATE, GET_BENCHMARK_COMPARISON } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  benchmarkComparisons: { // { [comparisonId]: { id: ... } }
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_BENCHMARK_COMPARISON: {
      return {
        ...state,
        benchmarkComparisons: {
          ...state.benchmarkComparisons,
          [action.payload.comparisonId]: action.payload.data,
        }
      };
    }

    default: {
      return state;
    }
  }
};
