/*
import Firebase from './firebase';
import JWTAuth from './jwt';
import BasicAuth from './Basic';
*/
import SupabaseAuth from './supabase';

export const AuhMethods = {
  // firebase: Firebase,
  /* jwtAuth: JWTAuth,*/
  /* basic: BasicAuth, */
  supabase: SupabaseAuth,
};
