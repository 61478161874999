//For expanding sidebar
import { UPDATE_PORTFOLIO, GET_PORTFOLIO_POSITIONS, ADD_PORTFOLIO_POSITION, DEL_PORTFOLIO_POSITION } from '../../@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import axiosClient from 'services/auth/jwt/config';


// get portfolio positions
export const getPortfolioPositions = (portfolioId) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.get(`/portfolios/${portfolioId}/positions`);
      if (res.data) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_PORTFOLIO_POSITIONS,
          payload: { portfolioId, positions: res.data },
        });
      } else {
        dispatch(fetchError('Error while fetching portfolio positions'));
      }
    } catch (error) {
      dispatch(fetchError('Error while fetching portfolio positions'));
    }
  };
};

// add a position to a portfolio
export const addPortfolioPosition = (portfolioId, positionId) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.patch(`/portfolios/${portfolioId}/positions/${positionId}`);
      if (res.data) {
        dispatch(fetchSuccess());

        dispatch({
          type: ADD_PORTFOLIO_POSITION,
          payload: { portfolioId, position: res.data },
        });

        dispatch({
          type: UPDATE_PORTFOLIO,
          payload: res.data.portfolio,
        });

        dispatch(showMessage('Position added successfully!'));
      } else {
        dispatch(fetchError('Error while adding position to portfolio'));
      }
    } catch (error) {
      dispatch(fetchError('Error while adding position to portfolio'));
    }
  };
};

// delete a position from a portfolio
export const delPortfolioPosition = (portfolioId, positionId) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const res = await axiosClient.delete(`/portfolios/${portfolioId}/positions/${positionId}`);
      if (res.data) {
        dispatch(fetchSuccess());

        dispatch({
          type: DEL_PORTFOLIO_POSITION,
          payload: { portfolioId, positionId },
        });

        dispatch({
          type: UPDATE_PORTFOLIO,
          payload: res.data.portfolio,
        });

        dispatch(showMessage('Position deleted successfully!'));
      } else {
        dispatch(fetchError('Error while deleting position from portfolio'));
      }
    } catch (error) {
      dispatch(fetchError('Error while deleting position from portfolio'));
    }
  };
}
