import {
  CLEAR_STATE,
  CREATE_BENCHMARK,
  GET_BENCHMARKS,
  UPDATE_BENCHMARK,
  DELETE_BENCHMARK,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  benchmarks: {
    data: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_BENCHMARKS: {
      return {
        ...state,
        benchmarks: action.payload,
      };
    }

    case CREATE_BENCHMARK: {
      return {
        ...state,
        benchmarks: {
          data: [...action.payload, ...state.benchmarks.data],
          totalCount: state.benchmarks.totalCount + 1,
        },
      };
    }

    case UPDATE_BENCHMARK: {
      const updatedBenchmark = action.payload;
      const updatedBenchmarks = state.benchmarks.data.map(benchmark => {
        if (benchmark.id === updatedBenchmark.id) {
          return updatedBenchmark;
        }
        return benchmark;
      });

      return {
        ...state,
        benchmarks: {
          data: updatedBenchmarks,
          totalCount: state.benchmarks.totalCount,
        },
      };
    }

    case DELETE_BENCHMARK: {
      const deletedBenchmarkId = action.payload;
      const updatedBenchmarks = state.benchmarks.data.filter(benchmark => benchmark.id !== deletedBenchmarkId);
      return {
        ...state,
        benchmarks: {
          data: updatedBenchmarks,
          totalCount: state.benchmarks.totalCount - 1,
        },
      };
    }

    default: {
      return state;
    }
  }
};
