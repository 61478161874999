import { CLEAR_STATE, GET_PORTFOLIO_POSITIONS, ADD_PORTFOLIO_POSITION, DEL_PORTFOLIO_POSITION } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  portfolioPositions: {
    positions: {}, // { [portfolioId]: [position1, position2, ...] }
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_PORTFOLIO_POSITIONS: {
      return {
        ...state,
        portfolioPositions: {
          positions: {
            ...state.portfolioPositions.positions,
            [action.payload.portfolioId]: action.payload.positions,
          },
        }
      };
    }

    case ADD_PORTFOLIO_POSITION: {
      return {
        ...state,
        portfolioPositions: {
          positions: {
            ...state.portfolioPositions.positions,
            [action.payload.portfolioId]: [
              ...state.portfolioPositions.positions[action.payload.portfolioId],
              action.payload.position,
            ],
          },
        }
      };
    }

    case DEL_PORTFOLIO_POSITION: {
      return {
        ...state,
        portfolioPositions: {
          positions: {
            ...state.portfolioPositions.positions,
            [action.payload.portfolioId]: state.portfolioPositions.positions[action.payload.portfolioId].filter(position => position.id !== action.payload.positionId),
          },
        }
      };
    }

    default: {
      return state;
    }
  }
};
