import axios from 'axios';
import { STORAGE_ITEM_TOKEN } from '@jumbo/constants/LocalStorage';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(request => {
  const jwtToken = localStorage.getItem(STORAGE_ITEM_TOKEN);
  if (jwtToken) { 
    request.headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    console.log('No token found');
  }
  return request;
});

export default axiosClient;