import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorView from 'components/ErrorView';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TableCell } from '@material-ui/core';

// FIX https://github.com/gregnb/mui-datatables/issues/1893
const oldRender = TableCell.render;
TableCell.render = function(...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  } else {
    return oldRender.apply(this, args);
  }
};

export const store = configureStore();
export const queryClient = new QueryClient();
ReactGA.initialize('G-YXX9EDRRF2');

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppContextProvider>
              <AppWrapper>
                <Switch>
                  <Routes />
                </Switch>
              </AppWrapper>
            </AppContextProvider>
          </ConnectedRouter>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
export default App;
