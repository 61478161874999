import {
  CLEAR_STATE,
  CREATE_POSITIONS,
  GET_POSITIONS,
  DELETE_POSITION,
  UPDATE_POSITION,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  positions: {
    data: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_POSITIONS: {
      return {
        ...state,
        positions: action.payload,
      };
    }

    case CREATE_POSITIONS: {
      return {
        ...state,
        positions: {
          data: [...action.payload, ...state.positions.data],
          totalCount: state.positions.totalCount + 1,
        },
      };
    }

    case UPDATE_POSITION: {
      const updatedPosition = action.payload;
      const updatedPositions = state.positions.data.map(position => {
        if (position.id === updatedPosition.id) {
          return updatedPosition;
        }
        return position;
      });

      return {
        ...state,
        positions: {
          data: updatedPositions,
          totalCount: state.positions.totalCount,
        },
      };
    }

    case DELETE_POSITION: {
      const deletedPositionId = action.payload;
      const updatedPositions = state.positions.data.filter(position => position.id !== deletedPositionId);
      return {
        ...state,
        positions: {
          data: updatedPositions,
          totalCount: state.positions.totalCount - 1,
        },
      };
    }

    default: {
      return state;
    }
  }
};
