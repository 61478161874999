import IntlMessages from '../../../utils/IntlMessages';
import {
  DonutSmall,
  MonetizationOn,
  ShowChart,
} from '@material-ui/icons';
import React from 'react';

const dashboardsMenus = [
  {
    name: <IntlMessages id={'sidebar.dashboard.portfolios'} />,
    icon: <DonutSmall />,
    type: 'item',
    link: '/dashboard/portfolios',
  },
  {
    name: <IntlMessages id={'sidebar.dashboard.benchmarks'} />,
    icon: <ShowChart />,
    type: 'item',
    link: '/dashboard/benchmarks',
  },
  {
    name: <IntlMessages id={'sidebar.dashboard.positions'} />,
    icon: <MonetizationOn />,
    type: 'item',
    link: '/dashboard/positions',
  }
];

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.dashboards'} />,
    type: 'section',
    children: dashboardsMenus,
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.dashboards'} />,
    type: 'collapse',
    children: dashboardsMenus,
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.dashboards'} />,
    type: 'collapse',
    children: dashboardsMenus,
  },
];
