import {
  CLEAR_STATE,
  CREATE_PORTFOLIO,
  GET_PORTFOLIOS,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  portfolios: {
    data: [],
    totalCount: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return INIT_STATE;
    }

    case GET_PORTFOLIOS: {
      return {
        ...state,
        portfolios: action.payload,
      };
    }

    case CREATE_PORTFOLIO: {
      return {
        ...state,
        portfolios: {
          data: [...action.payload, ...state.portfolios.data],
          totalCount: state.portfolios.totalCount + 1,
        },
      };
    }

    case UPDATE_PORTFOLIO: {
      const updatedPortfolio = action.payload;
      const updatedPortfolios = state.portfolios.data.map(portfolio => {
        if (portfolio.id === updatedPortfolio.id) {
          return updatedPortfolio;
        }
        return portfolio;
      });

      return {
        ...state,
        portfolios: {
          data: updatedPortfolios,
          totalCount: state.portfolios.totalCount,
        },
      };
    }

    case DELETE_PORTFOLIO: {
      const deletedPortfolioId = action.payload;
      const updatedPortfolios = state.portfolios.data.filter(portfolio => portfolio.id !== deletedPortfolioId);
      return {
        ...state,
        portfolios: {
          data: updatedPortfolios,
          totalCount: state.portfolios.totalCount - 1,
        },
      };
    }

    default: {
      return state;
    }
  }
};
