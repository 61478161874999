import React, { Suspense, lazy }  from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { withErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ErrorView from 'components/ErrorView';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

import Dashboards from './Dashboards';

const buildLazyComponent = importer => withErrorBoundary(lazy(importer), { FallbackComponent: ErrorView });

const Login = buildLazyComponent(() => import('./Auth/Login'));
const Signup = buildLazyComponent(() => import('./Auth/Register'));
const ForgotPassword = buildLazyComponent(() => import('./Auth/ForgotPassword'));
const TourGuide = buildLazyComponent(() => import('./TourGuide'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/signin" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <RestrictedRoute path="/dashboard" component={Dashboards} />
      </Switch>

      {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )}
    </Suspense>
  );
};

export default Routes;
